$(function () {
    // SHOW MORE TEXT DESSCRIPTION AFTER CLICKED ON BTN
    $('.displayMoreInfo').click(function (e) {
        e.preventDefault();

        var slideTxtH = $(this).siblings('.slideTxt').height() + 80;

        $(this).toggleClass('active');

        if ($(this).parent().hasClass('expanded')) {
            $(this).parent().toggleClass('expanded').animate({
                transition: 0.3,
                height: '100'
            });
        } else {
            $(this).parent().toggleClass('expanded').animate({
                transition: 0.3,
                height: slideTxtH
            });
        }
    });
});

// SHOW MORE TEXT BIULETYN AFTER CLICKED ON BTN
$(function () {
    if ($('.biuletynSection .descriptionBox').height() > $('.iframeSlider').height()) {
        $('.biuletynMoreInfo').show();
        $(".biuletynSection .descriptionBox").css('max-height', $('.iframeSlider').height());
    }

    else if ($(window).width() < 991) {
        $('.biuletynMoreInfo').show();
       // $(".biuletynSection .descriptionBox").css('max-height', '300');
       $(".biuletynSection .descriptionBox").style.maxHeight = "300px";  
    }

    else {
        $('.biuletynMoreInfo').hide();
    }

    $('.biuletynMoreInfo').click(function (e) {
        e.preventDefault();

        $(this).toggleClass('active');
        $(".biuletynSection .descriptionBox").removeClass('expanded');

        if ($(this).hasClass('active')) {
            $(".biuletynSection .descriptionBox").addClass('expanded');
            $(this).text('Pokaż mniej');
        } else {
            $(".biuletynSection .descriptionBox").removeClass('expanded');
            $(this).text('Pokaż więcej');
        }
    });
});